<template>
  <v-container fluid class="pl-6" ref="top">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Editar Titular</h1>
      </v-col>
    </v-row>

    <v-row v-if="changedFields && changedFields.length > 0">
      <v-col cols="12" class="d-flex align-center justify-end">
        <LegendsChip :legends="legends" />
      </v-col>
    </v-row>

    <div ref="holderComponent">
      <HolderInformation
        @disabledHolderInformation="disabledHolderInformation"
        @saveAdmissionDate="saveAdmissionDate"
        :changedFields="changedFields"
      />
    </div>

    <div ref="planComponent">
      <PlanInformation
        :admissionDateHolder="admissionDateHolder"
        :propsIsDisabledHolderInformation="isDisabledHolderInformation"
        @disabledPlanInformation="disabledPlanInformation"
        @saveValidatyDate="saveValidatyDate"
        :changedFields="changedFields"
      />
    </div>

    <div ref="beneficiaryAdditionComponent">
      <BeneficiaryAdditionalInformation
        @disabledAdditionalInformation="disabledAdditionalInformation"
        :changedFields="changedFields"
      />
    </div>

    <div ref="addressInformationComponent">
      <AddressInformations
        :showHeaderPage="true"
        :isFreeMovement="isFreeMovement"
        :isDetail="false"
        :addressData="holderAlteration.activeAddress"
        @disabledAddressInformation="disabledAddressInformation"
        :changedFields="changedFields"
      />
    </div>

    <div ref="bankInformationComponent">
      <BankInformation
        @disabledBankInformation="disabledBankInformation"
        :changedFields="changedFields"
      />
    </div>

    <div ref="uploadDocumentComponent">
      <UploadDocuments
        :showEditAndSaveButton="true"
        :movementRecord="movementRecord"
        @disabledUploadDocuments="disabledUploadDocuments"
        :changedFields="changedFields"
      />
    </div>

    <div v-if="violations.length > 0">
      <ViolationRulesAlert :violations="violations" />
    </div>

    <div v-if="isCriticizedCarrier || isCriticizedBroker" style="position: absolute; z-index: 1;">
      <ViewCriticizedViolationDialog />
    </div>

    <v-dialog v-model="confirmReturnModal" persistent :max-width="600">
      <v-card class="overflow-y-auto pa-5">
        <v-card-title class="pa-5">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-icon large color="green">fa-check</v-icon>
              <v-row class="mb-3" justify="center" style="word-break: break-word">
                <v-col cols="12" align="center">
                  <label class="label primary&#45;&#45;text font-italic" style="word-break: break-word">
                    Movimentação cadastrada com sucesso!
                  </label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-row class="ma-1 justify-center">
            <v-btn class="pa-7" color="#3B495B" style="color: white" @click="closeConfirmReturnModal()">
              IR PARA A LISTA DE MOVIMENTAÇÕES
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>

    <v-footer class="my-10">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            v-if="!isCriticizedBroker && !isCriticizedCarrier && !isEdit"
            x-large
            block
            text
            color="textPrimary"
            @click="onClickBack()"
          >
            Voltar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickCancel()"
          >
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            @click="openViewCriticismAndInternalNotesModal()"
          >
            Concluir
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <ReleaseMovementModal
      ref="ReleaseMovementModal"
      @close="closeConfirmReturnModal()"
      @confirm="updateStatus()"
    />

    <TransitionMovementModal
      ref="TransitionMovementModal"
      @makeNewMovement="backToAlterationPage"
      @goToMovementList="goToMovementPage"
    />

    <InfoMovementDialog
      :show="showReleaseInfo" :movementType="movementType"
      :messageType="releaseMessageType"
      :beneficiaryName="holderAlteration ? formatter.formatToTitleCase(holderAlteration.physicalPersonName) : null"
      @close="closeConfirmReturnModal()"
    />

    <DynamicDialogInformation ref="DynamicDialogInformation">
      <v-row class="justify-scrollcenter mb-5" slot="textInfo">
        <span style="word-break: break-word">Nenhuma informação foi alterada!</span>
      </v-row>
    </DynamicDialogInformation>

    <ViewCriticismAndInternalNotesModal
      ref="ViewCriticismAndInternalNotesModal"
      @setSnackbarCustomize="setSnackbarCustomize()"
      @confirmCorrectionMovement="confirmCorrectionMovement"
    />

    <CriticismHandlingModal ref="CriticismHandlingModal" @confirm="openViewCriticismAndInternalNotesModal" />

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import lodash from 'lodash';
import moment from 'moment';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import Formatters from '@/shared/formatters/formatters';
import HolderInformation from '@/components/ManualMovement/Alteration/HolderInformation.vue';
import PlanInformation from '@/components/ManualMovement/Alteration/PlanInformation.vue';
import BeneficiaryAdditionalInformation from '@/components/ManualMovement/Alteration/beneficiaryAdditionalInformation.vue';
import AddressInformations from '@/components/Beneficiary/Holder/AddressInformations.vue';
import BankInformation from '@/components/ManualMovement/Alteration/bankInformation.vue';
import UploadDocuments from '@/components/Beneficiary/Documents/UploadDocuments.vue';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import Rule from '@/shared/validators/formRules';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import UserUtils from '@/shared/utils/user-utils';
import InfoMovementDialog from '@/components/MovementDialog/InfoMovementDialog.vue';
import ChangedFieldsMixin from '@/shared/mixins/highlightedChangedFields/changedFieldsMixin';
import LegendsChip from '@/components/Legends/LegendsChip.vue';
import ReleaseMovementModal from '@/components/ReleaseMovement/ReleaseMovementModal.vue';
import ViewCriticismAndInternalNotesModal from '@/components/DashboardMovementRecords/Movements/Modals/ViewCriticismAndInternalNotes/ViewCriticismAndInternalNotesModal.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import { store } from '@/shared/observable/store';
import CriticismHandlingModal from '@/components/CustomAlerts/CriticismHandlingModal.vue';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import TransitionMovementModal from '@/components/TransitionMovementModal/TransitionMovementModal.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ManualMovementAlteration',
  components: {
    InfoMovementDialog,
    ViolationRulesAlert,
    HolderInformation,
    PlanInformation,
    BeneficiaryAdditionalInformation,
    AddressInformations,
    BankInformation,
    UploadDocuments,
    DynamicDialogInformation,
    ViewCriticizedViolationDialog,
    LegendsChip,
    ReleaseMovementModal,
    ViewCriticismAndInternalNotesModal,
    SnackbarCustomize,
    CriticismHandlingModal,
    TransitionMovementModal,
  },
  data: () => ({
    isRHProtegido: false,
    isDisabledHolderInformation: true,
    isDisabledPlanInformation: true,
    isDisabledAdditionalInformation: true,
    isDisabledAddressInformation: true,
    isDisabledBankInformation: true,
    isDisabledUploadDocuments: true,
    isCriticizedCarrier: false,
    isCriticizedBroker: false,
    isEdit: false,
    overlay: false,
    currentObjContractPlan: {},
    physicalPersonData: {},
    isFreeMovement: false,
    codeMessage: '',
    admissionDateHolder: '',
    validatyDate: '',
    holderAlteration: {},
    movementRecord: {},
    confirmReturnModal: false,
    violations: [],
    hasAuthorityReleaseMovement: false,
    showReleaseInfo: false,
    movementType: 'Alteração',
    successReleaseMessageType: 'RELEASE_SUCCESS',
    errorReleaseMessageType: 'RELEASE_ERROR',
    releaseMessageType: '',
    waitingRulesApprovalIds: [],
    changedFields: [],
    internalCriticisms: [],
    skipDuplicityCheck: false,
    persistWithCriticism: false,
  }),

  mixins: [
    ChangedFieldsMixin,
    VerifyRoutesMixin,
    GenericFunctionsMixin
  ],

  async mounted() {
    this.verifyTypeOperationForMovement();
    this.$refs.holderComponent.scrollIntoView({ behavior: 'smooth' });
    await this.loadAuthorityMovement();
    this.checkMovementType();
    this.createAlterationObjectInSessionStorage();
    await this.insertContractId();
    if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
      this.isFreeMovement = true;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (sessionStorage.getItem('holderAlteration')) {
        this.admissionDateHolder = this.holderAlteration && this.holderAlteration.admissionDate ? this.formatter.formatDate(this.holderAlteration.admissionDate) : null;
        this.validatyDate = this.holderAlteration && this.holderAlteration.startDate ? this.formatter.formatDate(this.holderAlteration.startDate) : null;
      }
    }
  },

  computed: {
    ...mapGetters({
      searchParams: 'handler/searchParams',
    }),
    legends() {
      return [
        {
          color: '#f4f4f4',
          icon: 'mdi-circle',
          iconColor: 'rgb(25, 0, 255)',
          text: 'Sofreu alteração',
        },
      ];
    },
    getObservableStore() {
      return store.dialogInformation;
    },
  },

  watch: {
    getObservableStore(val) {
      if (val && !val.show) {
        if (val.viewMovements) {
          sessionStorage.setItem('movementRecordIds', JSON.stringify(val.data.movementRecordIds));
          this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
        }

        if (val.skipDuplicityCheck) {
          this.skipDuplicityCheck = true;
          this.openViewCriticismAndInternalNotesModal();
        }
      }
    },
  },

  methods: {
    ...mapMutations({
      setSearchParams: 'handler/setSearchParams',
    }),
    setSessionStoreData() {
      if (sessionStorage.getItem('searchParams')) {
        this.setLayout(JSON.parse(sessionStorage.getItem('searchParams')));
        sessionStorage.removeItem('searchParams')
      }
    },
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async checkMovementType() {
      if (sessionStorage.getItem('movementRecord')) {
        this.movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        this.insertAddressInformationForCriticizedMovement();
        if (this.isEdit) {
          await this.loadChangedFieldsByMovementRecordId(this.movementRecord.id);
        }
      }
    },
    async insertContractId() {
      if (!this.isFreeMovement) {
        if (sessionStorage.getItem('holderAlteration')) {
          this.holderAlteration = JSON.parse(sessionStorage.getItem('holderAlteration'));
          this.physicalPersonId = this.holderAlteration && this.holderAlteration.physicalPersonId ? this.holderAlteration.physicalPersonId : null;
          this.currentObjContractPlan = {};
          this.currentObjContractPlan.contract = this.holderAlteration && this.holderAlteration.contractId ? this.holderAlteration.contractId : null;
          this.currentObjContractPlan.subcontract = this.holderAlteration && this.holderAlteration.subcontractId ? this.holderAlteration.subcontractId : null;
          this.currentObjContractPlan.contractPolicy = this.holderAlteration && this.holderAlteration.contractPolicy ? this.holderAlteration.contractPolicy : null;
          this.currentObjContractPlan.subContractNumber = this.holderAlteration && this.holderAlteration.subcontractNumber ? this.holderAlteration.subcontractNumber : null;
          this.currentObjContractPlan.financialGroup = this.holderAlteration && this.holderAlteration.financialGroupId ? this.holderAlteration.financialGroupId : null;
          this.currentObjContractPlan.previousSubContractNumber = this.holderAlteration && this.holderAlteration.subcontractNumber ? this.holderAlteration.subcontractNumber : null;
          this.currentObjContractPlan.capacityCode = this.holderAlteration && this.holderAlteration.capacityCode ? this.holderAlteration.capacityCode : null;
          this.currentObjContractPlan.capacityDescription = this.holderAlteration && this.holderAlteration.capacityDescription ? this.holderAlteration.capacityDescription : null;
          this.currentObjContractPlan.department = this.holderAlteration && this.holderAlteration.department ? this.holderAlteration.department : null;
          sessionStorage.setItem('alterationObject', JSON.stringify(this.currentObjContractPlan));
        } else if (sessionStorage.getItem('movementRecord')) {
          const alterationObject = JSON.parse(sessionStorage.getItem('alterationObject'));
          this.currentObjContractPlan = {};
          if (this.movementRecord) {
            alterationObject.contract = this.movementRecord.contractId ? this.movementRecord.contractId : null;
            alterationObject.subcontract = this.movementRecord.subContractId ? this.movementRecord.subContractId : null;
            alterationObject.contractPolicy = this.movementRecord.policy ? this.movementRecord.policy : null;
            alterationObject.subContractNumber = this.movementRecord.subContract ? this.movementRecord.subContract : null;
            alterationObject.financialGroup = this.movementRecord.financialGroupId ? this.movementRecord.financialGroupId : null;
            alterationObject.previousSubContractNumber = this.movementRecord.previousSubContractNumber ? this.movementRecord.previousSubContractNumber : null;
            alterationObject.capacityCode = this.movementRecord.capacityCode ? this.movementRecord.capacityCode : null;
            alterationObject.capacityDescription = this.movementRecord.capacityDescription ? this.movementRecord.capacityDescription : null;
            alterationObject.department = this.movementRecord.department ? this.movementRecord.department : null;
            sessionStorage.setItem('alterationObject', JSON.stringify(alterationObject));
          }
        }

        if (sessionStorage.getItem('dependentAlteration')) {
          const obj = {};
          const dependentAlteration = JSON.parse(sessionStorage.getItem('dependentAlteration'));
          obj.contract = dependentAlteration.contractId ? dependentAlteration.contractId : null;
          sessionStorage.setItem('alterationObject', JSON.stringify(obj));
        }
      }
    },
    async insertAddressInformationForCriticizedMovement() {
      this.holderAlteration.activeAddress = {
        code: this.movementRecord.addressZip,
        log: this.movementRecord.address,
        city: this.movementRecord.addressCity,
        region: this.movementRecord.addressState,
        neighborhood: this.movementRecord.addressNeighborhood,
        complement: this.movementRecord.addressComplement,
        logType: this.movementRecord.addressType,
        number: this.movementRecord.addressNumber,
        country: this.movementRecord.addressCountry,
        ibgeCode: this.movementRecord.ibgeCode,
      };
    },
    createAlterationObjectInSessionStorage() {
      sessionStorage.setItem('alterationObject', JSON.stringify({}));
    },
    onClickBack() {
      this.clearSessionStorage();
      if (this.isCriticizedCarrier) {
        this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido });
      } else {
        this.redirectRouter('SearchHolderDependent', { isRHProtegido: this.isRHProtegido });
      }
    },
    onClickCancel() {
      const { isRHProtegido } = this;
      let name;

      if (this.isEdit || this.isCriticizedBroker || this.isCriticizedCarrier) {
        name = 'Movement';
      } else {
        this.clearSessionStorage();
        name = isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      }

      this.redirectRouter(name, { isRHProtegido });
    },
    openViewCriticismAndInternalNotesModal(isContinue) {
      this.persistWithCriticism = !!isContinue;
      if (this.isCriticizedCarrier) {
        const queryParams = {
          movementRecord: this.movementRecord,
          isRHProtegido: this.isRHProtegido,
          viewType: 'confirmCorrectionMovement',
        };

        this.$refs.ViewCriticismAndInternalNotesModal.open(queryParams);
      } else {
        this.onClickFinish();
      }
    },
    confirmCorrectionMovement(data) {
      this.internalCriticisms = data;
      this.$refs.ViewCriticismAndInternalNotesModal.close();
      this.onClickFinish();
    },
    async onClickFinish() {
      this.violations = [];

      if (this.isAllSectionsDisabled()) {
        this.overlay = true;
        const data = this.prepareData();

        if (data) {
          this.payload = data;
          if (this.isEdit || this.isCriticizedBroker) {
            await this.handleEditAndCriticizedBroker();
          } else {
            this.handleUpdateMovement();
          }
        } else {
          this.showDynamicDialog();
        }
      } else {
        this.handleSectionsNotDisabled();
      }
    },
    isAllSectionsDisabled() {
      return (
        this.isDisabledHolderInformation
        && this.isDisabledPlanInformation
        && this.isDisabledAdditionalInformation
        && this.isDisabledAddressInformation
        && this.isDisabledUploadDocuments
        && this.isDisabledBankInformation
      );
    },
    prepareData() {
      let data = {
        ...this.prepareContractInfo(),
        additionalInfo: this.prepareAdditionalInfo(),
        bank: this.prepareBankInfo(),
        holder: this.prepareHolderInfo(),
        plan: this.preparePlanInfo(),
        address: this.prepareAddressInfo(),
        documents: this.prepareDocumentsInfo(),
        internalCriticisms: this.internalCriticisms,
        skipDuplicityCheck: this.skipDuplicityCheck ? this.skipDuplicityCheck : false,
        persistWithCriticism: this.persistWithCriticism ? this.persistWithCriticism : false,
      };

      data = this.formatter.validateEmptyValues(data);

      return this.checkEmptyFields(data);
    },
    prepareContractInfo() {
      const contractInfo = {};
      if (sessionStorage.getItem('alterationObject')) {
        const storedContractInfo = JSON.parse(sessionStorage.getItem('alterationObject'));
        contractInfo.contract = storedContractInfo && storedContractInfo.contract ? storedContractInfo.contract : null;
        contractInfo.subcontract = storedContractInfo && storedContractInfo.subcontract ? storedContractInfo.subcontract : null;
        contractInfo.contractPolicy = storedContractInfo && storedContractInfo.contractPolicy ? storedContractInfo.contractPolicy : null;
        contractInfo.previousSubContractNumber = storedContractInfo && storedContractInfo.previousSubContractNumber ? storedContractInfo.previousSubContractNumber : null;
        contractInfo.subContractNumber = storedContractInfo && storedContractInfo.subContractNumber ? storedContractInfo.subContractNumber : null;
      }
      return { ...contractInfo };
    },
    preparePlanInfo() {
      const planInfo = {};
      const contractInfo = JSON.parse(sessionStorage.getItem('alterationObject'));
      if (contractInfo && contractInfo.plan) {
        planInfo.contract = contractInfo.plan.contract ? contractInfo.plan.contract : null;
        planInfo.subcontract = contractInfo.plan.subcontract ? contractInfo.plan.subcontract : null;
        planInfo.eligibility = contractInfo.plan.eligibility ? contractInfo.plan.eligibility : null;
        planInfo.financialGroupId = contractInfo.plan.financialGroupId ? contractInfo.plan.financialGroupId : null;
        planInfo.plan = contractInfo.plan.plan ? contractInfo.plan.plan : null;
        planInfo.previousPlan = contractInfo.plan.previousPlan ? contractInfo.plan.previousPlan : null;
        planInfo.planChangeReasonCode = contractInfo.plan.planChangeReasonCode ? contractInfo.plan.planChangeReasonCode : null;
        planInfo.validityDate = contractInfo.plan.validityDate ? contractInfo.plan.validityDate : null;
        planInfo.familyCode = contractInfo.plan.familyCode ? contractInfo.plan.familyCode : null;
        planInfo.familyCodeSequential = contractInfo.plan.familyCodeSequential ? contractInfo.plan.familyCodeSequential : null;
        planInfo.hasAcceptedGracePeriod = contractInfo.plan.hasAcceptedGracePeriod;
        planInfo.unitId = contractInfo.plan.unitId;
        planInfo.unitCode = contractInfo.plan.unitCode;
        planInfo.unitDescription = contractInfo.plan.unitDescription;
      }
      return planInfo;
    },
    prepareHolderInfo() {
      const holderChanged = JSON.parse(sessionStorage.getItem('alterationObject'));
      const holder = {};

      if (holderChanged) {
        holder.cpf = holderChanged.holder.document ? holderChanged.holder.document : null;
        holder.name = holderChanged.holder.fullname ? holderChanged.holder.fullname : null;
        holder.admissionDate = holderChanged.holder.admissionDate ? holderChanged.holder.admissionDate : null;
        holder.cardNumber = holderChanged.holder.cardNumber ? holderChanged.holder.cardNumber : null;
        if (this.isFreeMovement) {
          holder.registrationPlate = this.isFreeMovement ? holderChanged.holder.registration : null;
        }
      }
      return holder;
    },
    prepareAdditionalInfo() {
      let additionalInfo = JSON.parse(sessionStorage.getItem('beneficiaryAdditionalInfoUpdated'));
      const alterationObject = JSON.parse(sessionStorage.getItem('alterationObject'));
      additionalInfo.department = alterationObject.holder
                                    && alterationObject.holder.department
                                      ? alterationObject.holder.department
                                      : null;
      additionalInfo.capacityCode = alterationObject.holder
                                      && alterationObject.holder.capacityCode
                                        ? alterationObject.holder.capacityCode
                                        : null;
      additionalInfo.capacityDescription = alterationObject.holder
                                            && alterationObject.holder.capacityDescription
                                            ? alterationObject.holder.capacityDescription
                                            : null;
      return additionalInfo || {};
    },
    prepareAddressInfo() {
      const address = JSON.parse(sessionStorage.getItem('beneficiaryAddressUpdated'));
      return address;
    },
    prepareBankInfo() {
      const bank = JSON.parse(sessionStorage.getItem('beneficiaryBankUpdated'));
      return bank || {};
    },
    prepareDocumentsInfo() {
      const documents = JSON.parse(sessionStorage.getItem('documents'));
      return documents || [];
    },
    checkEmptyFields(data) {
      if (data.address && data.address.log === '') {
        data.address.country = null;
      }

      if (data.additionalInfo) {
        data.additionalInfo = this.checkEmptyFieldAdditionalInformation(data.additionalInfo);
      }

      if (data.address === null) {
        delete data.address;
      }

      if (!this.isFreeMovement) {
        this.handleHolderAlteration(data);
        this.handleCriticizedCarrier(data);
      }

      if (this.isCriticizedCarrier) {
        data.movementRecordParentId = this.movementRecord && this.movementRecord.id ? this.movementRecord.id : null;
      }

      return data;
    },
    handleHolderAlteration(data) {
      const holderAlterationInfo = JSON.parse(sessionStorage.getItem('holderAlteration'));
      if (holderAlterationInfo) {
        data.beneficiaryContractedPlanId = holderAlterationInfo.contractPlanId || null;
        data.id = holderAlterationInfo.memberId || null;
      }
    },
    handleCriticizedCarrier(data) {
      if (this.isCriticizedCarrier) {
        data.beneficiaryContractedPlanId = this.movementRecord?.beneficiaryContractedPlanId || null;
      }
    },
    showDynamicDialog() {
      this.$refs.DynamicDialogInformation.open();
      this.overlay = false;
    },
    async handleEditAndCriticizedBroker() {
      await this.formatPayloadEditAndCriticizedBroker();

      let criticsm = [];

      if (sessionStorage.getItem('criticismRules')) {
        criticsm = JSON.parse(sessionStorage.getItem('criticismRules')).filter((movement) => movement.classification && movement.classification === 'INTERNAL').map((movement) => movement.id);
      }

      this.payload = lodash.cloneDeep({
        ...this.payload,
        internalCriticisms: criticsm,
      });

      await this.processMovementRecordService(this.movementRecordService.UpdateAlterationHolder, this.movementRecord.id, this.payload, this.isFreeMovement, this.isCriticizedBroker);
    },
    handleUpdateMovement() {
      let criticsm = [];

      if (sessionStorage.getItem('criticismRules')) {
        criticsm = JSON.parse(sessionStorage.getItem('criticismRules')).filter((movement) => movement.classification && movement.classification === 'INTERNAL').map((movement) => movement.id);
      }

      this.payload = lodash.cloneDeep({
        ...this.payload,
        internalCriticisms: criticsm,
      });

      this.processMovementRecordService(this.movementRecordService.UpdateHolder, this.payload, this.isFreeMovement);
    },
    handleSectionsNotDisabled() {
      this.scrollTo();
      this.setSnackbarCustomize('error', 'Existem informações sendo alteradas neste formulário, Por favor, salve o tópico em aberto.');
    },
    checkEmptyFieldAdditionalInformation(additionalInfo) {
      const entries = Object.entries(additionalInfo);
      const updatedEntries = entries.map(([key, value]) => {
        if (value === '' || value === '0.00') {
          return [key, null];
        }
        return [key, value];
      });
      return Object.fromEntries(updatedEntries);
    },
    async formatPayloadEditAndCriticizedBroker() {

      const { payload } = this;
      const { plan } = payload;
      const { additionalInfo } = payload;
      const { address } = payload;

      payload.plan = {
        contractId: payload.contract,
        subContractId: plan.subcontract,
        eligibilityId: plan.eligibility,
        planId: plan.plan,
        validityDate: plan.validityDate,
        financialGroupId: plan.financialGroupId,
        planChangeReasonCode: plan.planChangeReasonCode,
        familyCode: plan.familyCode,
        familyCodeSequential: plan.familyCodeSequential,
        unitId: plan.unitId,
        unitCode: plan.unitCode,
        unitDescription: plan.unitDescription,
      };
      payload.additionalInfo = {
        birthDate: additionalInfo.birthday,
        maritalStatusId: additionalInfo.maritalStatus,
        genderId: additionalInfo.gender,
        insuredPosition: additionalInfo.job,
        department: additionalInfo.department,
        costCenterDepartment: additionalInfo.department,
        taxAllocationCode: additionalInfo.capacityCode,
        taxAllocationDescription: additionalInfo.capacityDescription,
        capacityCode: additionalInfo.capacityCode,
        capacityDescription: additionalInfo.capacityDescription,
        employmentRelationshipId: additionalInfo.employmentRelationship,
        motherName: additionalInfo.motherName,
        nationality: additionalInfo.nationality,
        pisPasep: additionalInfo.pisPasep,
        email: additionalInfo.email,
        professionalCardNumber: additionalInfo.workCard,
        cns: additionalInfo.cns,
        dnv: additionalInfo.dnv ? this.formatter.unmaskDnv(additionalInfo.dnv): null,
        weight: additionalInfo.weight,
        height: additionalInfo.height,
        rne: additionalInfo.rne,
        cellphone: additionalInfo.cellphone,
        telephone: additionalInfo.telephone,
        identityDocumentNature: additionalInfo.identityDocumentNature,
        identityDocumentNumber: additionalInfo.identityDocumentNumber,
        identityDocumentEmissionDate: additionalInfo.identityDocumentEmissionDate,
        identityDocumentIssuer: additionalInfo.identityDocumentIssuer,
        identityDocumentState: additionalInfo.identityDocumentState,
        identityDocumentCountry: additionalInfo.identityDocumentCountry,
        companyName: additionalInfo.companyName,
        companyDocumentNumber: additionalInfo.companyDocumentNumber,
        stateBirth: additionalInfo.stateBirth,
        registrationPlate: additionalInfo.registration,
        registrationComplement: additionalInfo.registrationComplement,
      };
      payload.address = {
        log: address.log,
        type: address.logType,
        zip: address.code,
        city: address.city,
        state: address.region,
        country: address.country,
        neighborhood: address.neighborhood,
        number: address.number,
        complement: address.complement,
        ibgeCode: address.ibgeCode,
      };
      this.payload = payload;

      delete payload.subcontract;
      delete payload.subContractNumber;
      delete payload.previousSubContractNumber;
      delete payload.contractPolicy;
      delete payload.contract;
    },
    async processMovementRecordService(serviceMethod, ...args) {
      this.violations = [];
      this.overlay = true;

      try {
        const response = await serviceMethod.call(this.movementRecordService, ...args);
        const { recordList } = response.data;

        this.waitingRulesApprovalIds = recordList.filter((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS').map((movement) => movement.id);

        if (this.hasAuthorityReleaseMovement && this.waitingRulesApprovalIds.length > 0) {
          const releaseMovementData = {
            beneficiaryName: this.holderAlteration.physicalPersonName,
            movementType: this.movementType,
          };

          this.$refs.ReleaseMovementModal.open(releaseMovementData);
        } else {
          this.openConfirmReturnModal();
        }
      } catch (error) {
        this.overlay = false;
        this.isLoadingSave = false;
        this.documentsData = [];

        if (error.response && error.response.status === 422) {
          this.violations = [...error.response.data.violations];

          this.$refs.CriticismHandlingModal.open();
        }
      }
    },
    disabledHolderInformation(isDisabled) {
      this.isDisabledHolderInformation = isDisabled;
    },
    disabledPlanInformation(isDisabled) {
      this.isDisabledPlanInformation = isDisabled;
    },
    disabledAdditionalInformation(isDisabled) {
      this.isDisabledAdditionalInformation = isDisabled;
    },
    disabledAddressInformation(isDisabled) {
      this.isDisabledAddressInformation = isDisabled;
    },
    disabledBankInformation(isDisabled) {
      this.isDisabledBankInformation = isDisabled;
    },
    disabledUploadDocuments(isDisabled) {
      this.isDisabledUploadDocuments = isDisabled;
    },
    scrollTo() {
      if (!this.isDisabledHolderInformation) {
        this.$refs.holderComponent.scrollIntoView({ behavior: 'smooth' });
      }
      if (!this.isDisabledPlanInformation) {
        this.$refs.planComponent.scrollIntoView({ behavior: 'smooth' });
      }
      if (!this.isDisabledAdditionalInformation) {
        this.$refs.beneficiaryAdditionComponent.scrollIntoView({ behavior: 'smooth' });
      }
      if (!this.isDisabledAddressInformation) {
        this.$refs.addressInformationComponent.scrollIntoView({ behavior: 'smooth' });
      }
      if (!this.isDisabledBankInformation) {
        this.$refs.bankInformationComponent.scrollIntoView({ behavior: 'smooth' });
      }
      if (!this.isDisabledUploadDocuments) {
        this.$refs.uploadDocumentComponent.scrollIntoView({ behavior: 'smooth' });
      }
    },
    saveAdmissionDate(admissionDate) {
      this.admissionDateHolder = admissionDate;
    },
    saveValidatyDate(validatyDate) {
      this.validatyDate = validatyDate;
    },
    openConfirmReturnModal() {
      this.confirmReturnModal = true;
    },
    closeConfirmReturnModal() {
      this.$refs.ReleaseMovementModal.close();

      if (!this.isEdit && !this.isCriticizedBroker && !this.isCriticizedCarrier) {
        const releaseMovementData = {
          beneficiaryName: this.holderAlteration.physicalPersonName,
          movementType: this.movementType,
        };

        this.$refs.TransitionMovementModal.open(releaseMovementData);
      } else {
        this.goToMovementPage();
      }
    },
    backToAlterationPage() {
      this.$refs.TransitionMovementModal.close();
      this.redirectRouter('SearchHolderDependent', { isRHProtegido: this.isRHProtegido }, { creation: true })
    },
    goToMovementPage() {
      this.setSearchParams({});
      this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
      this.confirmReturnModal = false;
      this.overlay = false;
      this.clearSessionStorage();
      this.$refs.TransitionMovementModal.close();
    },
    async loadAuthorityMovement() {
      this.hasAuthorityReleaseMovement = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_movimentacoes_cadastrais_liberar_movimentacao' });
    },
    async updateStatus() {
      const movementRecordService = new MovementRecordService();
      this.$refs.ReleaseMovementModal.close();

      if (this.hasAuthorityReleaseMovement && this.waitingRulesApprovalIds.length > 0) {
        const request = {
          ids: [...this.waitingRulesApprovalIds],
          status: 'VALID',
        };

        await movementRecordService.UpdateStatusFromMultipleMovements(request).then(() => {
          this.releaseMessageType = this.successReleaseMessageType;
          this.closeConfirmReturnModal();
          this.setSnackbarCustomize('success', 'Movimentação liberada com sucesso');
        }).catch(() => {
          this.releaseMessageType = this.errorReleaseMessageType;
          this.closeConfirmReturnModal();
          this.setSnackbarCustomize('error', 'Ocorreu um erro ao liberar a movimentação');
        });
      } else {
        this.releaseMessageType = this.errorReleaseMessageType;
        this.closeConfirmReturnModal();
        this.setSnackbarCustomize('error', 'Você não tem permissão para liberar a movimentação');
      }
    },
    setSnackbarCustomize(type, message) {
      this.$refs.SnackbarCustomize.open(type, message);
    },
  },
  async created() {
    this.createAlterationObjectInSessionStorage();
    this.userUtils = new UserUtils();
    this.formatter = new Formatters();
    this.movementRecordService = new MovementRecordService();
    this.rule = new Rule();
  },
};
</script>