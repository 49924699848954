<template>
  <v-container fluid>
    <v-row class="mt-4" align="start" ref="top">
      <v-col cols="12" md="6">
        <p class="title">
          {{ movementType && movementType === "insert" ? "Inclusão de Grupo Familiar:" : "Implantação:" }}
          Adicionar Titular
        </p>
      </v-col>
      <v-col cols="12" lg="6">
        <v-stepper tile value="3">
          <v-stepper-header>
            <v-stepper-step complete step="1"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step complete step="2"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" color="textPrimary">
              Endereço e Dados Bancários
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4"></v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <v-container fill-height fluid class="mt-0 pt-0">
      <SimplifiedPrincipalInformations :beneficiaryContractedPlanProps="beneficiaryContractedPlan" />
    </v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-form ref="formRegister" v-model="isValidForm" lazy-validation>
          <v-card class="px-6 py-6" elevation="1">
            <AddressInformations
              :showHeaderPage="true"
              :isDetail="false"
              :addressData="address"
              :isFreeMovement="false"
              @observerAddressData="updateAddressData"
              @updateAddressData="updateAddressData"
            />

            <v-row>
              <v-col cols="12">
                <p class="title">Dados Bancários</p>
              </v-col>
            </v-row>
            <v-row class="mr-1">
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="bank.code"
                  :items="banks"
                  item-text="description"
                  item-value="code"
                  outlined
                  label="Cód. do Banco"
                  placeholder="Cod. do Banco"
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="bank.agency"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  @blur="bank.agency ? rule.verifyNegative(bank.agency, 'fieldCheckedBankAgency') : ''"
                  @focus="verifyMessageBankAgency = rule.isFocusVerifyNegative(true, bank.agency)"
                  @change="verifyMessageBankAgency = rule.isFocusVerifyNegative(false, bank.agency)"
                  id="fieldCheckedBankAgency"
                  v-mask="'#########'"
                  :error-messages="verifyMessageBankAgency ? '*Preencha corretamente' : ''"
                  label="Agência"
                  placeholder="Informe apenas números"
                  :rules="bank.agency ? [rule.validateNegativeDecimal(bank.agency, 'int')] : []"
                  validate-on-blur
                  color="textPrimary"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field
                  v-model="bank.agencyDigit"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  @blur="bank.agencyDigit ? rule.verifyNegative(bank.agencyDigit, 'fieldCheckedBankAgencyDigit') : ''"
                  @focus="verifyMessageBankAgencyDigit = rule.isFocusVerifyNegative(true, bank.agencyDigit)"
                  @change="verifyMessageBankAgencyDigit = rule.isFocusVerifyNegative(false, bank.agencyDigit)"
                  id="fieldCheckedBankAgencyDigit"
                  :error-messages="verifyMessageBankAgencyDigit ? '*Preencha corretamente' : ''"
                  v-mask="'#'"
                  label="DV"
                  color="textPrimary"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="bank.account"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  @blur="bank.account ? rule.verifyNegative(bank.account, 'fieldCheckedBankAccount') : ''"
                  @focus="verifyMessageBankAccount = rule.isFocusVerifyNegative(true, bank.account)"
                  @change="verifyMessageBankAccount = rule.isFocusVerifyNegative(false, bank.account)"
                  id="fieldCheckedBankAccount"
                  v-mask="'#########'"
                  :error-messages="verifyMessageBankAccount ? '*Preencha corretamente' : ''"
                  label="Nº da Conta"
                  :rules="bank.account ? [rule.validateNegativeDecimal(bank.account, 'int')] : []"
                  validate-on-blur
                  placeholder="Informe apenas números"
                  color="textPrimary"
                  outlined
                />
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field
                  v-model="bank.accountDigit"
                  @keydown="$event.key === '-' ? $event.preventDefault() : null"
                  @blur="bank.accountDigit ? rule.verifyNegative(bank.accountDigit, 'fieldCheckedBankAccountDigit') : ''"
                  @focus="verifyMessageBankAccountDigit = rule.isFocusVerifyNegative(true, bank.accountDigit)"
                  @change="verifyMessageBankAccountDigit = rule.isFocusVerifyNegative(false, bank.accountDigit)"
                  id="fieldCheckedBankAccountDigit"
                  v-mask="'#'"
                  validate-on-blur
                  label="DV"
                  :error-messages="verifyMessageBankAccountDigit ? '*Preencha corretamente' : ''"
                  color="textPrimary"
                  outlined
                />
              </v-col>
            </v-row>
          </v-card>
          <v-row v-if="documentsList.length > 0" align="center" class="mt-4">
            <v-col cols="12">
              <v-card class="px-6 py-6" elevation="1">
                <v-row class="px-3">
                  <v-col cols="12">
                    <p class="title">Upload de documentos</p>
                  </v-col>
                </v-row>
                <v-row class="mb-3 mx-3" style="border-style: dashed; border-width: 2px; border-color: var(--v-primary-base);">
                  <v-col v-for="(item, index) in documentsList" :key="index" cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
                    <label class="label"> {{ item.description }} </label>
                    <v-file-input
                      class="mt-2"
                      v-model="item.file"
                      @change="uploadDocument(item)"
                      accept="application/pdf, image/jpg, image/jpeg, image/png, image/bmp, image/tiff, image/webp"
                      label="Clique para adicionar"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon=""
                      color="textPrimary"
                      outlined
                      :disabled="waitingDocumentUpload"
                      :messages="waitingDocumentUpload ? 'Enviando seu documento... Por favor, aguarde.' : ''"
                      :loading="waitingDocumentUpload"
                      :rules="item.file ? [rule.validateUploadFiles(item)] : []"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          small
                          label
                          color="primary"
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <div v-if="violations.length > 0">
          <ViolationRulesAlert :violations="violations" />
        </div>
      </v-col>
    </v-row>

    <v-footer>
      <v-row class="d-flex justify-end my-6">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2" class="pt-0">
          <v-btn
            x-large
            block
            text
            color="textPrimary"
            @click="onClickBack()"
          >
            <v-icon
              left
              dark>
              fas fa-chevron-left
            </v-icon>
            Voltar
          </v-btn>
        </v-col>
        <v-btn
          class="mr-6"
          x-large
          outlined
          color="textPrimary"
          width="250px"
          @click="onClickCancel()"
        >
          Cancelar
        </v-btn>
        <v-btn
          ref="buttonNext"
          class="white--text mr-10"
          x-large
          color="textPrimary"
          width="250px"
          :disabled="waitingDocumentUpload"
          @click="onClickFinish()"
          :loading="isLoadingValidation"
        >
          {{ continueWithCriticism ? 'Prosseguir com crítica' : 'Próximo' }}
          <v-icon
            right
            dark>
            fas fa-chevron-right
          </v-icon>
        </v-btn>
      </v-row>
    </v-footer>

    <div v-if="isCriticizedCarrier">
      <ViewCriticizedViolationDialog />
    </div>

    <SnackbarCustomize ref="SnackbarCustomize" />

  </v-container>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import BankService from '@/services-http/sdi/BankService';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import { store } from '@/shared/observable/store';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import SimplifiedPrincipalInformations from '@/components/Beneficiary/Holder/SimplifiedPrincipalInformations.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import AddressInformations from '@/components/Beneficiary/Holder/AddressInformations.vue';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import MovementRecordFormFieldsToValidate from '@/shared/validators/movementRecordFormFieldsToValidate';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';

export default {
  name: 'AddressBankInformation',

  components: {
    ViolationRulesAlert, SimplifiedPrincipalInformations, ViewCriticizedViolationDialog, AddressInformations, SnackbarCustomize,
  },

  data: () => ({
    address: {},
    bank: {
      code: null,
      agency: null,
      agencyDigit: null,
      account: null,
      accountDigit: null,
    },
    beneficiaryContractedPlan: {
      cpf: null,
      name: null,
      admissionDate: null,
      plans: [],
    },
    documentsList: [],
    documentsData: [],
    isValidForm: true,
    log: null,
    banks: [],
    movementType: null,
    isRHProtegido: false,
    insuranceId: '',
    verifyMessageBankAgency: false,
    verifyMessageBankAgencyDigit: false,
    verifyMessageBankAccount: false,
    verifyMessageBankAccountDigit: false,
    waitingDocumentUpload: false,
    isCriticizedCarrier: false,
    dependentsList: [],
    documentDependents: [],
    isLoadingValidation: false,
    hasViolation: false,
    violations: [],
    loadedHolder: {},
    continueWithCriticism: false,
  }),

  watch: {
    address:{
      handler(val){
        if(this.continueWithCriticism){
          this.continueWithCriticism=false;
        }
      },
      deep: true,
      immediate: true,
    },
    bank: {
      handler(val) {
        if (val.code === '') {
          this.bank.code = null;
        }
        if (val.agency === '') {
          this.bank.agency = null;
        }
        if (val.agencyDigit === '') {
          this.bank.agencyDigit = null;
        }
        if (val.account === '') {
          this.bank.account = null;
        }
        if (val.accountDigit === '') {
          this.bank.accountDigit = null;
        }

        this.continueWithCriticism = false;
      },
      deep: true,
      immediate: true,
    },
    getObservableStore(val) {
      if (val && !val.show) {
        this.findDocumentType();
      }
    },
  },

  async mounted() {
    this.scrollTo();
    await this.loadBanks();
    await this.loadDataSessionStorage();
    this.findDocumentType();
  },

  computed: {
    getObservableStore() {
      return store.dialogInformation;
    },
  },

  mixins: [
    ConstantsMixin,
    VerifyRoutesMixin,
    GenericFunctionsMixin,
  ],

  methods: {
    async loadBanks() {
      this.bankService.FindAll().then((response) => {
        if (response && response.data && response.data.content) {
          let banks = response.data.content;
          banks = banks.map((bank) => ({
            id: bank.id,
            description: bank && bank.code ? `${bank.code} - ${bank.name}` : bank.name,
            code: bank.code,
          }));
          this.banks = banks;
        }
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao carregar a lista de bancos.');
      });
    },
    loadHolder() {
      if (sessionStorage.getItem('hasHolder')) {
        this.loadedHolder = JSON.parse(sessionStorage.getItem('hasHolder'));
        this.loadAddress();
        this.loadBank();
      }
    },
    loadAddress() {
      this.address.code = this.loadedHolder.addressZip;
      this.address.log = this.loadedHolder.address;
      this.address.city = this.loadedHolder.addressCity;
      this.address.region = this.loadedHolder.addressState;
      this.address.logType = this.loadedHolder.addressType;
      this.address.neighborhood = this.loadedHolder.addressNeighborhood;
      this.address.number = this.loadedHolder.addressNumber;
      this.address.complement = this.loadedHolder.addressComplement;
      this.address.ibgeCode = this.loadedHolder.ibgeCode;
    },
    loadBank() {
      this.bank.code = this.loadedHolder.bank ? Number(this.loadedHolder.bank) : null;
      this.bank.agency = this.loadedHolder.branchBank;
      this.bank.account = this.loadedHolder.bankAccount;
      this.bank.agencyDigit = this.loadedHolder.branchBankCheckDigit;
      this.bank.accountDigit = this.loadedHolder.bankAccountCheckDigit;
    },
    async loadDataSessionStorage() {
      if (sessionStorage.getItem('beneficiaryContractedPlan')) {
        this.beneficiaryContractedPlan = JSON.parse(sessionStorage.getItem('beneficiaryContractedPlan'));
      }
      if (sessionStorage.getItem('isCriticizedCarrier') && sessionStorage.getItem('movementRecord')) {
        this.isCriticizedCarrier = true;
        this.movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        await this.loadDocumentsByMovementRecordId();
        this.loadDependents();
      }
      if (sessionStorage.getItem('beneficiaryAddress')) {
        this.address = JSON.parse(sessionStorage.getItem('beneficiaryAddress'));
      }
      if (sessionStorage.getItem('beneficiaryBank')) {
        this.bank = JSON.parse(sessionStorage.getItem('beneficiaryBank'));
      }
      if (sessionStorage.getItem('documents')) {
        this.documentsData = JSON.parse(sessionStorage.getItem('documents'));
      }

      this.violations = sessionStorage.getItem('beneficiaryAddressViolations') ? JSON.parse(sessionStorage.getItem('beneficiaryAddressViolations')) : [];
    },
    onClickBack() {
      this.address.code = this.address.code ? this.formatter.removeNonDigit(this.address.code) : '';
      sessionStorage.setItem('beneficiaryAddress', JSON.stringify(this.address));
      sessionStorage.setItem('beneficiaryBank', JSON.stringify(this.bank));
      sessionStorage.setItem('documents', JSON.stringify(this.documentsData));
      const query = {
        movementType: this.movementType,
        isRHProtegido: this.isRHProtegido,
        insuranceCarrierId: this.insuranceId,
        beneficiaryType: 'HOLDER',
      };

      this.redirectRouter('BeneficiaryAdditionalInformation', query);
    },
    onClickCancel() {
      this.clearSessionStorage();
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    onClickFinish() {
      if (!this.continueWithCriticism) {
        this.violations = [];
      }

      const {
        code, log, city, region, logType, neighborhood, number, complement, ibgeCode,
      } = this.address;

      const formatCode = code ? code.replace('-', '') : null;
      this.address.code = formatCode;
      this.address.country = !code && !log && !city && !region && !logType && !neighborhood && !number && !complement ? null : 'Brasil';
      if (city === '') this.address.city = null;

      if (this.$refs.formRegister.validate()) {
        sessionStorage.setItem('beneficiaryAddress', JSON.stringify(this.address));
        sessionStorage.setItem('beneficiaryBank', JSON.stringify(this.bank));
        sessionStorage.setItem('documents', JSON.stringify(this.documentsData));
        if (sessionStorage.getItem('movementRecord')) {
          sessionStorage.setItem('dependentsList', JSON.stringify(this.dependentsList));
        }
        const query = {
          movementType: this.movementType,
          isRHProtegido: this.isRHProtegido,
          insuranceCarrierId: this.insuranceId,
          beneficiaryType: 'DEPENDENT',
        };

        this.isLoadingValidation = true;

        const insertToValidate = {
          contractedPlan: this.beneficiaryContractedPlan,
          additionalInfo: JSON.parse(sessionStorage.getItem('beneficiaryAdditionalInfo')),
          address: this.address,
          bank: this.bank,
        };

        const fieldsToValidate = this.fieldsToValidate.getFieldsToValidate('addressBankInformation');
        const movementType = this.movementType ? this.movementType.toLowerCase() : null;
        const functionValidate = movementType === 'insert' ? this.movementRecordService.ValidateInsert(insertToValidate, fieldsToValidate) : this.movementRecordService.ValidateImplantation(insertToValidate, fieldsToValidate);

        if (!this.continueWithCriticism) {
          functionValidate.then(() => {
            this.isLoadingValidation = false;
            this.redirectRouter('Dependents', query);
          }).catch((error) => {
            this.isLoadingValidation = false;
            if (error.response.status === 422) {
              this.violations = [...error.response.data.violations];
              this.continueWithCriticism = true;
              this.$refs.SnackbarCustomize.open('error', 'Erro de validação de regras no preenchimento do formulário');
            }
          });
        } else {
          sessionStorage.setItem('beneficiaryAddressViolations', JSON.stringify(this.violations));
          this.redirectRouter('Dependents', query);
        }
      }
    },
    findDocumentType() {
      const movementType = this.$route.query && this.$route.query.movementType ? this.$route.query.movementType.toUpperCase() : '';
      this.insuranceId = this.$route.query && this.$route.query.insuranceCarrierId ? this.$route.query.insuranceCarrierId : null;
      const beneficiaryType = this.$route.query && this.$route.query.beneficiaryType ? this.$route.query.beneficiaryType : null;
      const query = `movementType=${movementType}&insuranceCarrierId=${this.insuranceId}&beneficiaryType=${beneficiaryType}`;

      this.documentTypeService.FindAllDocumentType(query).then((response) => {
        this.documentsList = response.data.content.map((element) => ({
          id: element.id,
          name: element.name,
          description: element.description,
          file: null,
        }));
        if (this.documentsList && this.documentsList.length > 0) {
          if (sessionStorage.getItem('documents')) {
            if (this.documentsData && this.documentsData.length > 0) {
              this.documentsList.forEach((documentList) => {
                this.documentsData.forEach((documents) => {
                  if (documentList.name === documents.name) {
                  // eslint-disable-next-line no-param-reassign
                    documentList.file = new File([documents.titleDocument], documents.titleDocument, { type: documents.type });
                  }
                });
              });
            }
          }
        }
      });
    },
    uploadDocument(item) {
      if (item) {
        if ((item.file && !this.rule.isValidFileSize(item.file.size)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpg' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png' && item.file.type !== 'image/bmp' && item.file.type !== 'image/tiff' && item.file.type !== 'image/webp'))) {
          return;
        }
        if (item.file !== null && !!item.file) {
          this.waitingDocumentUpload = true;
          this.documentService.UploadDocument(item.id, item.file).then((response) => {
            this.documentsData.push({
              id: response.data,
              name: item.name,
              file: item.file,
              titleDocument: item.file.name,
              type: item.file.type,
            });
            this.waitingDocumentUpload = false;
          }).catch(() => {
            this.waitingDocumentUpload = false;
          });
        } else {
          this.clearUploadDocument(item);
        }
      }
    },
    clearUploadDocument(document) {
      this.documentsData.forEach((element, index) => {
        // eslint-disable-next-line no-lonely-if
        if (element.name === document.name) {
          this.documentsData.splice(index, 1);
        }
      });
    },
    loadDependents() {
      if (this.movementRecord && this.movementRecord.dependents && this.movementRecord.dependents.length > 0) {
        this.movementRecord.dependents.forEach((dependent) => {
          this.dependentsList.push({
            document: dependent.insuredDocumentNumber,
            fullName: dependent.insuredName ? this.formatter.formatToTitleCase(dependent.insuredName) : null,
            birthDate: dependent.birthDate,
            maritalStatus: dependent.maritalStatus,
            gender: dependent.gender,
            relationship: dependent.degreeKinship,
            cns: dependent.cns,
            dnv: dependent.dnv,
            motherName: dependent.motherName ? this.formatter.formatToTitleCase(dependent.motherName) : null,
            height: dependent.height,
            weight: dependent.weight,
            plans: this.beneficiaryContractedPlan.plans,
            documents: this.documentDependents,
            movementRecordParentId: dependent.id,
            address: this.address,
          });
        });
      }
    },
    async loadDocumentsByMovementRecordId() {
      if (!sessionStorage.getItem('dependentsList')) {
        if (this.movementRecord.dependents && this.movementRecord.dependents.length > 0) {
          this.movementRecord.dependents.forEach((movementDependent) => {
            const queryString = `?movementRecordId=${movementDependent.id}`;
            this.documentService.FindDocumentByFilters(queryString).then((response) => {
              if (response && response.data && response.data.length > 0) {
                response.data.forEach((document) => {
                  this.documentDependents.push({
                    file: {},
                    id: document.id,
                    name: document.documentType.name,
                    titleDocument: document.documentType.name,
                    type: 'image/png',
                  });
                });
              }
            });
          });
        }
      }
    },
    scrollTo() {
      this.$refs.top.scrollIntoView({ behavior: 'smooth' });
    },
    updateAddressData(data) {
      this.address.code = data.code;
      this.address.log = data.log;
      this.address.city = data.city;
      this.address.region = data.region;
      this.address.logType = data.logType;
      this.address.country = data.country;
      this.address.neighborhood = data.neighborhood;
      this.address.number = data.number;
      this.address.complement = data.complement;
      this.address.ibgeCode = data.ibgeCode;
    },
  },

  created() {
    this.movementRecordService = new MovementRecordService();
    this.fieldsToValidate = new MovementRecordFormFieldsToValidate();
    this.documentService = new DocumentService();
    this.documentTypeService = new DocumentTypeService();
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.movementType = this.$route.query.movementType;
    this.bankService = new BankService();
    this.loadHolder();
  },
};
</script>
